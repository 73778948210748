<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="กรุณาเลือก" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>ข้อตกลงการเปิดร้าน "UniLive"</h2>
		<p>ข้อตกลงการเปิดร้าน UniLive คือ Pix Technology PTE. LTD.
			ข้อตกลงทางอิเล็กทรอนิกส์ที่มีผลตามกฎหมายที่กำหนดสิทธิและหน้าที่ของทั้งสองฝ่ายที่ตกลงกันโดย LTD. ("เรา") และคุณ
			(คุณเป็นบุคคลธรรมดานิติบุคคลหรือองค์กรอื่น ๆ ) ต่อไปนี้จะเรียกว่า "ข้อตกลงนี้" เมื่อท่านทำเครื่องหมาย "ฉันยอมรับ"
			หรือคลิกที่ปุ่ม "ฉันได้อ่านและปฏิบัติตามข้อตกลงนี้แล้ว" แสดงว่าท่านได้อ่านอย่างรอบคอบ เข้าใจอย่างถ่องแท้
			และยอมรับเงื่อนไขทั้งหมดของข้อตกลงนี้โดยไม่มีการสงวนสิทธิ์
		</p>
		<h3>วัตถุประสงค์ของข้อตกลงแรก </h3>
		<p>ข้อตกลงนี้มีวัตถุประสงค์เพื่อชี้แจงสิทธิและหน้าที่ระหว่างร้านค้าและแพลตฟอร์ม UniLive
			ควบคุมพฤติกรรมทางธุรกิจของร้านค้ารักษาสิทธิของผู้บริโภคและส่งเสริมการพัฒนาที่ดีของแพลตฟอร์ม UniLive
			เพื่อให้มั่นใจว่าสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของทุกฝ่ายในการทำธุรกรรม
		</p>
		<h3>คำจำกัดความข้อ 2 </h3>
		<p>1, UniLive:
			หมายถึงผู้ดำเนินการแอปนี้และบริการที่เกี่ยวข้องแพลตฟอร์มที่ให้บริการการซื้อขายออนไลน์และการสนับสนุนการบริการ </p>
		<p>
			2. ร้านค้า: หมายถึงบุคคลหรือธุรกิจที่ลงทะเบียนและดำเนินกิจกรรมทางธุรกิจบน UniLive รวมถึงแต่ไม่ จำกัด
			เฉพาะนักธุรกิจรายบุคคล บริษัท และนิติบุคคลอื่น ๆ
		</p>
		<p>
			3. ผู้บริโภค: หมายถึง ผู้ที่ซื้อสินค้าหรือบริการบน UniLive
		</p>
		<h3>ข้อที่ 3 การเข้ามาของร้านค้า</h3>
		<p>
			1, ข้อกำหนดในการลงทะเบียน: ร้านค้าต้องกรอกข้อมูลส่วนบุคคลหรือธุรกิจที่แท้จริงและถูกต้องบน UniLive รวมถึงชื่อ,
			รายละเอียดการติดต่อ, ใบอนุญาตประกอบธุรกิจ, ใบรับรองการจดทะเบียนภาษี ฯลฯ
			ร้านค้าควรปรับปรุงข้อมูลที่ลงทะเบียนให้ทันสมัยและทันเวลา เช่น มีการเปลี่ยนแปลงข้อมูลให้ทันสมัยภายใน 48 ชั่วโมง
		</p>
		<p>2, ขั้นตอนการตรวจสอบ: UniLive จะตรวจสอบข้อมูลที่ส่งโดยร้านค้าโดยปกติจะใช้เวลา 3-5 วันทำการ เมื่อรีวิวผ่านแล้ว
			ร้านค้าจะได้รับการแจ้งเตือนยืนยันการเปิดร้านอย่างเป็นทางการ
		</p>
		<p> 3 การตั้งค่าร้านค้า: ร้านค้าต้องตั้งค่าร้านค้าบน UniLive รวมถึงชื่อร้านค้าโลโก้โปรไฟล์การจำแนกสินค้า ฯลฯ
			เพื่อให้แน่ใจว่าข้อมูลเป็นไปตามข้อกำหนดของแพลตฟอร์ม
		</p>
		<h3>ข้อ 4 สิทธิและหน้าที่ของร้านค้า</h3>
		<p>
			1 โพสต์ข้อมูลสินค้าและทำการขายบน UniLive เพลิดเพลินกับบริการต่างๆที่นำเสนอโดยแพลตฟอร์ม
		</p>
		<p>2 เข้าร่วมโปรโมชั่นและโปรโมชั่นการตลาดของ UniLive เพื่อเพิ่มโอกาสในการเข้าถึง </p>
		<p>3 รับข้อมูลการขาย คำติชมจากผู้ใช้ และรายงานการวิเคราะห์ตลาดเพื่อเพิ่มประสิทธิภาพกลยุทธ์ทางธุรกิจ</p>
		<p>4, การปฏิบัติตามสินค้า:
			รับรองความถูกต้องตามกฎหมายความถูกต้องและการปฏิบัติตามสินค้าที่ขายและไม่ขายสินค้าปลอมและด้อยคุณภาพ</p>
		<p>5, อัปเดตข้อมูล: อัปเดตข้อมูลสินค้าอย่างสม่ำเสมอเพื่อให้แน่ใจว่าราคาสินค้าคงคลังข้อมูลการจัดส่งถูกต้องและชัดเจน
		</p>
		<p>6 การปฏิบัติตามกฎของแพลตฟอร์ม: การปฏิบัติตามกฎระเบียบการจัดการและข้อกำหนดในการดำเนินงานของ UniLive
			มีส่วนร่วมในการฝึกอบรมแพลตฟอร์มและเพิ่มขีดความสามารถในการดำเนินงาน </p>
		<h3>ข้อ 5 สิทธิและหน้าที่ของ UniLive </h3>
		<p>1 การตรวจสอบและจัดการข้อมูลที่เผยแพร่โดยร้านค้าเพื่อให้แน่ใจว่ามีการปฏิบัติตามข้อมูล </p>
		<p>2 ใช้มาตรการที่เหมาะสมกับร้านค้าที่ละเมิดข้อตกลง รวมถึงแต่ไม่จำกัดเพียง การตักเตือน การระงับ
			หรือการยุติร้านค้าจนกว่าจะมีการดำเนินคดีตามกฎหมาย</p>
		<p>3.
			ปรับปรุงนโยบายแพลตฟอร์มอย่างสม่ำเสมอตามการเปลี่ยนแปลงของสภาพตลาดและกฎหมายและข้อบังคับซึ่งร้านค้าควรทราบและปฏิบัติตามอย่างทันท่วงที
		</p>
		<p>4
			จัดหาแพลตฟอร์มการซื้อขายที่ปลอดภัยและมั่นคงเพื่อรับประกันความปลอดภัยในการทำธุรกรรมของร้านค้าและผู้บริโภคป้องกันการฉ้อโกงและการกระทำผิดกฎหมายอื่น
			ๆ </p>
		<p> 5 จัดการกับปัญหาและข้อเสนอแนะจากผู้ค้าในเวลาที่เหมาะสมให้การสนับสนุนด้านเทคนิคและการฝึกอบรมที่จำเป็น </p>
		<p>6 ให้บริการวิเคราะห์ข้อมูลทางธุรกิจ รายงานแนวโน้มตลาด และคำแนะนำการส่งเสริมการขายแก่ร้านค้าอย่างสม่ำเสมอ
			เพื่อช่วยให้ร้านค้าสามารถเพิ่มประสิทธิภาพได้ </p>
		<h3>ข้อ 6 ความรับผิดสำหรับการละเมิด</h3>
		<p>
			1. ในกรณีที่ร้านค้าละเมิดข้อกำหนดใด ๆ ของข้อตกลงนี้ UniLive
			มีสิทธิ์ที่จะขอให้ร้านค้าทำการแก้ไขและดำเนินการลงโทษที่เกี่ยวข้องอย่างเหมาะสม รวมถึงแต่ไม่ จำกัด
			เพียงการลดระดับร้านค้าการจำกัดสินค้าบนชั้นวางสินค้าการยกเลิกบริการ ฯลฯ
		</p>
		<p>2
			การร้องเรียนของผู้บริโภคที่เกิดจากสาเหตุของร้านค้าร้านค้าจะต้องรับผิดชอบทางกฎหมายและความรับผิดชอบในการชดเชยที่เกี่ยวข้อง
		</p>
		<p>3 หาก UniLive ไม่สามารถให้บริการได้ตามที่ตกลงกันไว้ ร้านค้าอาจเรียกร้องค่าเสียหายจาก UniLive
			โดยจำนวนเงินที่เฉพาะเจาะจงจะถูกกำหนดโดยการเจรจาต่อรองระหว่างทั้งสองฝ่าย</p>
		<h3>การเปลี่ยนแปลงและการยกเลิกข้อตกลงข้อ 7</h3>
		<p>1 การแก้ไขหรือการเปลี่ยนแปลงใด ๆ ในข้อตกลงนี้จะต้องได้รับความยินยอมจากทั้งสองฝ่ายและอาจถูกยกเลิก</p>
		<p>2, uniLive มีสิทธิ์ที่จะแก้ไขข้อตกลงเป็นระยะ ๆ
			และแจ้งให้ร้านค้าทราบอย่างทันท่วงทีตามกฎหมายและข้อบังคับการเปลี่ยนแปลงของตลาด ฯลฯ </p>
		<p>3 ร้านค้าต้องแจ้งให้ UniLive ทราบเป็นลายลักษณ์อักษรล่วงหน้า 7 วัน หากต้องการยกเลิกสัญญานี้
			และดำเนินการกับคำสั่งซื้อที่ยังไม่เสร็จสมบูรณ์และข้อร้องเรียนของผู้บริโภคอย่างเหมาะสมก่อนที่จะยกเลิกสัญญา</p>
		<p>4, UniLive
			มีสิทธิ์ที่จะยกเลิกข้อตกลงนี้ทันทีโดยไม่ต้องแจ้งให้ทราบล่วงหน้าเมื่อพบว่ามีการละเมิดอย่างร้ายแรงของร้านค้า</p>
		<h3>ข้อ 8 อื่นๆ </h3>
		<p>1, เงื่อนไขการรักษาความลับ: ความลับทางการค้าและข้อมูลอื่น ๆ
			ที่ทั้งสองฝ่ายได้รับทราบในระหว่างการปฏิบัติตามข้อตกลงจะถูกเก็บเป็นความลับและจะไม่เปิดเผยต่อบุคคลที่สาม
		</p>
		<p>2, เหตุสุดวิสัย: ในกรณีที่ไม่สามารถปฏิบัติตามข้อตกลงเนื่องจากปัจจัยสุดวิสัย
			(เช่นภัยพิบัติทางธรรมชาติสงครามการเปลี่ยนแปลงนโยบาย ฯลฯ ) คู่สัญญาจะไม่รับผิดชอบต่อการละเมิด
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>